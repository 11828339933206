// Generated by Framer (b35efa8)

import { addFonts, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/CISs_ZkEa";

const enabledGestures = {epvKjBLSH: {hover: true}};

const cycleOrder = ["epvKjBLSH"];

const variantClassNames = {epvKjBLSH: "framer-v-wn14v3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "epvKjBLSH", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "epvKjBLSH", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const isDisplayed = () => {
if (gestureVariant === "epvKjBLSH-hover") return true
return false
}

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-0kILq", sharedStyle.className, classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-wn14v3", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"epvKjBLSH"} ref={ref} style={{backgroundColor: "rgba(51, 51, 51, 0.25)", ...style}} transition={transition} variants={{"epvKjBLSH-hover": {backgroundColor: "rgba(51, 51, 51, 0.85)"}}} {...addPropertyOverrides({"epvKjBLSH-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.h3 className={"framer-styles-preset-7cubu2"} data-styles-preset={"CISs_ZkEa"} style={{"--framer-text-alignment": "center"}}>Kengyel ABC</motion.h3></React.Fragment>} className={"framer-k2g0ki"} layoutDependency={layoutDependency} layoutId={"GMhPteHAc"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} transition={transition} verticalAlignment={"center"} withExternalLayout/>)}</motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-0kILq [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0kILq * { box-sizing: border-box; }", ".framer-0kILq .framer-1bx7qxz { display: block; }", ".framer-0kILq .framer-wn14v3 { height: 259px; overflow: hidden; position: relative; width: 494px; }", ".framer-0kILq .framer-k2g0ki { flex: none; height: 41px; left: calc(50.00000000000002% - 278px / 2); position: absolute; top: calc(49.80694980694983% - 41px / 2); white-space: pre-wrap; width: 278px; word-break: break-word; word-wrap: break-word; }", ".framer-0kILq .framer-v-wn14v3 .framer-wn14v3 { cursor: pointer; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 259
 * @framerIntrinsicWidth 494
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"jHujU_Xnu":{"layout":["fixed","fixed"]}}}
 */
const FramerXZZPYaNgR: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerXZZPYaNgR;

FramerXZZPYaNgR.displayName = "Reference Kengyel ABC";

FramerXZZPYaNgR.defaultProps = {height: 259, width: 494};

addFonts(FramerXZZPYaNgR, [...sharedStyle.fonts])